/* eslint-disable react/prop-types */

import React from 'react';
import { graphql, Link } from 'gatsby';
import { Box } from 'theme-ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
  btnAlign,
  btnPrimary,
  displayLeftTextRightImgAlign,
  displayLeftTextAlign,
  imageAlign,
} from '../styles/displayLeftTextRightImg.module.css';
// Display Image styling
import '../styles/displayImage.css';

export const fragment = graphql`
  fragment displayLeftTextRightImgSection on WpPage_Pagetemplate_Sections_DisplayLeftTextRightImageSection {
    backgroundSection
    description
    subtitle
    subtitleColorDisplay
    title
    titleTextColor
    ctaButtonDisplay
    buttonDisplaySection {
      buttonText
      buttonUrl
    }
    displayImage {
      imageSize
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              layout: FULL_WIDTH
              quality: 100
              webpOptions: { quality: 70 }
            )
          }
        }
      }
    }
  }
`;

const DisplayLeftTextRightImg = ({
  displayImage,
  backgroundSection,
  description,
  subtitle,
  subtitleColorDisplay,
  title,
  titleTextColor,
  ctaButtonDisplay,
  buttonDisplaySection,
}) => {
  const image = getImage(displayImage.image.localFile);

  return (
    <Box
      sx={{ background: backgroundSection }}
      className={displayLeftTextRightImgAlign}
    >
      <div className={displayLeftTextAlign}>
        <div>
          <Box
            sx={{
              color: subtitleColorDisplay,
            }}
          >
            <span>{subtitle}</span>
          </Box>
          <Box sx={{ color: titleTextColor }}>
            <h3>{title}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
          </Box>
          {ctaButtonDisplay ? (
            <div className={btnAlign}>
              <Link className={btnPrimary} to={buttonDisplaySection.buttonUrl}>
                {buttonDisplaySection.buttonText}
              </Link>
            </div>
          ) : null}
        </div>
      </div>
      <div className={imageAlign}>
        <GatsbyImage
          image={image}
          className={displayImage.imageSize}
          alt={displayImage.image.altText}
        />
      </div>
    </Box>
  );
};

export default DisplayLeftTextRightImg;
