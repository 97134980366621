/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import { Box, Link } from 'theme-ui';
import {
  ctaComponentSection,
  ctaComponentSectionSubtitle,
  ctaComponentSectionTitle,
  ctaComponentSectionDescription,
  ctaComponentSectionBtnAlign,
  ctaComponentSectionBtn,
  btnSecondary,
} from '../styles/ctaComponent.module.css';

export const fragment = graphql`
  fragment ctaSection on WpPage_Pagetemplate_Sections_CtaSection {
    ctaSectionBackground
    description
    subtitleDisplay
    subtitle
    title
    ctaButtonDisplay
    ctaButton {
      buttonUrl
      ctaButtonText
      ctaButtonColor
      ctaButtonBorder
      ctaButtonColorText
      ctaButtonColorHover
      ctaButtonBorderHover
      ctaButtonTextHover
    }
  }
`;

const CTAComponent = ({
  ctaSectionBackground,
  subtitleDisplay,
  description,
  subtitle,
  title,
  ctaButtonDisplay,
  ctaButton,
}) => {
  return (
    <Box
      sx={{ background: ctaSectionBackground }}
      className={ctaComponentSection}
    >
      {subtitleDisplay ? (
        <div className={ctaComponentSectionSubtitle}>
          <span>{subtitle}</span>
        </div>
      ) : null}
      <div className={ctaComponentSectionTitle}>
        <h3>{title}</h3>
      </div>
      <div
        className={ctaComponentSectionDescription}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {ctaButtonDisplay ? (
        <div className={ctaComponentSectionBtnAlign}>
          <div className={ctaComponentSectionBtn}>
            <Link
              sx={{
                background: ctaButton.ctaButtonColor,
                borderColor: ctaButton.ctaButtonBorder,
                color: ctaButton.ctaButtonColorText,
                '&:hover': {
                  background: ctaButton.ctaButtonColorHover,
                  borderColor: ctaButton.ctaButtonBorderHover,
                  color: ctaButton.ctaButtonTextHover,
                },
              }}
              className={btnSecondary}
              href={ctaButton.buttonUrl}
            >
              {ctaButton.ctaButtonText}
            </Link>
          </div>
        </div>
      ) : null}
    </Box>
  );
};

export default CTAComponent;
