// extracted by mini-css-extract-plugin
export var btnPrimary = "heroVideo-module--btnPrimary--3wycF";
export var btnPrimaryBannerAlign = "heroVideo-module--btnPrimaryBannerAlign--tN8rI";
export var btnVideoBanner = "heroVideo-module--btnVideoBanner--6qlb7";
export var closeBtnModal = "heroVideo-module--closeBtnModal--H2vyu";
export var heroColumnLeft = "heroVideo-module--heroColumnLeft--cFuE5";
export var heroColumnRight = "heroVideo-module--heroColumnRight--fBIPS";
export var heroSection = "heroVideo-module--heroSection--AXhDd";
export var imagePlaceholderStyle = "heroVideo-module--imagePlaceholderStyle--JmSOi";
export var modalAlign = "heroVideo-module--modalAlign--kJnQV";
export var modalBg = "heroVideo-module--modalBg--QYYeX";
export var modalContent = "heroVideo-module--modalContent--78oA6";
export var modalVideo = "heroVideo-module--modal-video--3Tw7X";
export var modalVideoAlign = "heroVideo-module--modalVideoAlign--JuTaz";
export var modalVideoStyle = "heroVideo-module--modalVideoStyle--ivqcR";
export var spin = "heroVideo-module--spin--nIapm";
export var spinnerAlign = "heroVideo-module--spinnerAlign--63pHT";
export var spinnerStyle = "heroVideo-module--spinnerStyle--NF22C";
export var videoPlaceholder = "heroVideo-module--videoPlaceholder--0pdhV";