/* eslint-disable react/prop-types */

import React from 'react';
import { graphql, Link } from 'gatsby';
import { Box } from 'theme-ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
  displayRightTextLeftImgAlign,
  imageAlign,
  displayRightTextAlign,
  btnAlign,
  btnPrimary,
} from '../styles/displayRightTextLeftImg.module.css';
// Display Image styling
import '../styles/displayImage.css';

export const fragment = graphql`
  fragment displayRightTextLeftImgSection on WpPage_Pagetemplate_Sections_DisplayRightTextLeftImageSection {
    backgroundColorSection
    description
    subtitle
    title
    titleTextColor
    subtitleColor
    ctaButtonDisplay
    buttonDisplay {
      buttonText
      buttonUrl
    }
    displayImageLeft {
      imageSize
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              quality: 100
              webpOptions: { quality: 70 }
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
`;
const DisplayRightTextLeftImg = ({
  backgroundColorSection,
  displayImageLeft,
  subtitleColor,
  subtitle,
  title,
  titleTextColor,
  description,
  ctaButtonDisplay,
  buttonDisplay,
}) => {
  const displayImage = getImage(displayImageLeft.image.localFile);

  return (
    <Box
      sx={{ background: backgroundColorSection }}
      className={displayRightTextLeftImgAlign}
    >
      <div className={imageAlign}>
        <GatsbyImage
          image={displayImage}
          alt={displayImageLeft.image.altText}
          className={displayImageLeft.imageSize}
        />
      </div>
      <div className={displayRightTextAlign}>
        <div>
          <Box sx={{ color: subtitleColor }}>
            <span>{subtitle}</span>
          </Box>
          <Box sx={{ color: titleTextColor }}>
            <h3>{title}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: description,
              }}
            />
            {ctaButtonDisplay ? (
              <div className={btnAlign}>
                <Link className={btnPrimary} to={buttonDisplay.buttonUrl}>
                  {buttonDisplay.buttonText}
                </Link>
              </div>
            ) : null}
          </Box>
        </div>
      </div>
    </Box>
  );
};

export default DisplayRightTextLeftImg;
