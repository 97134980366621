/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Box } from 'theme-ui';

import {
  longCenteredTitleTextSection,
  titleAlign,
  paragraphAlign,
  imageSectionAlign,
  displayImage,
  columnsAlign,
  column,
  columnTitle,
} from '../styles/longCenteredTitleText.module.css';
import '../styles/longCenteredIconDisplay.css';

export const fragment = graphql`
  fragment longCenteredParagraphSection on WpPage_Pagetemplate_Sections_LongCenteredParagraphSection {
    backgroundColor
    titleDisplay
    sectionTitle
    descriptionDisplay
    sectionDescription
    imageDisplay
    sectionImage {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            webpOptions: { quality: 70 }
            quality: 100
          )
        }
      }
    }
    columnsDisplay
    textColumns {
      leftColumn {
        columnTitle
        columnDescription
        columnIconDisplay
        columnIcon {
          altText
          localFile {
            publicURL
          }
        }
      }
      rightColumn {
        columnTitle
        columnDescription
        columnIconDisplay
        columnIcon {
          altText
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;

const LongCenteredTitleText = ({
  backgroundColor,
  titleDisplay,
  sectionTitle,
  descriptionDisplay,
  sectionDescription,
  imageDisplay,
  sectionImage,
  columnsDisplay,
  textColumns,
}) => {
  const imageSection = getImage(sectionImage?.localFile);

  return (
    <Box
      sx={{ background: backgroundColor }}
      className={longCenteredTitleTextSection}
    >
      {titleDisplay ? (
        <div
          className={titleAlign}
          dangerouslySetInnerHTML={{ __html: sectionTitle }}
        />
      ) : null}
      {descriptionDisplay ? (
        <div
          className={paragraphAlign}
          dangerouslySetInnerHTML={{ __html: sectionDescription }}
        />
      ) : null}
      {imageDisplay ? (
        <div className={imageSectionAlign}>
          <GatsbyImage
            image={imageSection}
            alt={sectionImage?.altText}
            className={displayImage}
          />
        </div>
      ) : null}
      {columnsDisplay ? (
        <div className={columnsAlign}>
          <div className={column}>
            <div className={columnTitle}>
              <img
                srcSet={textColumns.leftColumn.columnIcon?.localFile.publicURL}
                className={textColumns.leftColumn.columnIconDisplay}
                alt={textColumns.leftColumn.columnIcon?.altText}
              />
              <h4>{textColumns.leftColumn.columnTitle}</h4>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: textColumns.leftColumn.columnDescription,
              }}
            />
          </div>
          <div className={column}>
            <div className={columnTitle}>
              <img
                srcSet={textColumns.rightColumn.columnIcon?.localFile.publicURL}
                className={textColumns.rightColumn.columnIconDisplay}
                alt={textColumns.rightColumn.columnIcon?.altText}
              />
              <h4>{textColumns.rightColumn.columnTitle}</h4>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: textColumns.rightColumn.columnDescription,
              }}
            />
          </div>
        </div>
      ) : null}
    </Box>
  );
};

export default LongCenteredTitleText;
