/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import {
  sectionDividerStyle,
  sectionDividerSize,
} from '../styles/sectionDivider.module.css';

export const fragment = graphql`
  fragment sectionDivider on WpPage_Pagetemplate_Sections_SectionDivider {
    dividerImage {
      altText
      localFile {
        publicURL
      }
    }
  }
`;

const SectionDivider = ({ dividerImage }) => {
  return (
    <div className={sectionDividerStyle}>
      <img
        src={dividerImage?.localFile.publicURL}
        alt={dividerImage?.altText}
        className={sectionDividerSize}
      />
    </div>
  );
};

export default SectionDivider;
