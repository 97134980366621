/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/Seo';
import Layout from '../components/Layout/Layout';
import HeroVideo from './components/HeroVideo';
import HeroImage from './components/HeroImage';
import ProductPageHero from './components/ProductPageHero';
import TextHero from './components/TextHero';
import Banner from './components/Banner';
import TeamMember from './components/TeamMember';
import Product from './components/Product';
import Benefits from './components/Benefits';
import FeaturesDefinition from './components/FeaturesDefinition';
import Features from './components/Features';
import LongCenteredTitleText from './components/LongCenteredTitleText';
import CTAComponent from './components/CTAComponent';
import SingleImage from './components/SingleImage';
import QuoteComponent from './components/QuoteComponent';
import DisplayLeftTextRightImg from './components/DisplayLeftTextRightImg';
import DisplayRightTextLeftImg from './components/DisplayRightTextLeftImg';
import SectionDivider from './components/SectionDivider';
import Faq from './components/Faq';
import ContactUsSection from './components/ContactUsSection';
import TextComponent from './components/TextComponent';

const Page = ({ data }) => {
  const sections = data.wpPage.pageTemplate.sections || [];

  const { seo } = data.wpPage;

  return (
    <Layout>
      <Seo
        title={seo?.title}
        description={seo?.metaDesc}
        canonical={seo?.canonical}
        image={seo?.opengraphImage?.localFile.publicURL}
        imagesAlt={seo?.opengraphImage?.altText}
        schema={seo?.schema.raw}
      />
      {sections?.map((section) => {
        // eslint-disable-next-line no-underscore-dangle
        const typeName = section.__typename;

        switch (typeName) {
          case 'WpPage_Pagetemplate_Sections_Hero':
            return <HeroVideo key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_HeroImage':
            return <HeroImage key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_HeroProductPage':
            return <ProductPageHero key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_TextHeroSection':
            return <TextHero key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_BannerSection':
            return <Banner key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_TeamMembersSection':
            return <TeamMember key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_TextComponentSection':
            return <TextComponent key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_ProductDescription':
            return <Product key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_BenefitStatementsSection':
            return <Benefits key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_FeaturesDefinitionSection':
            return <FeaturesDefinition key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_FeaturesSection':
            return <Features key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_LongCenteredParagraphSection':
            return <LongCenteredTitleText key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_CtaSection':
            return <CTAComponent key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_ImageSection':
            return <SingleImage key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_QuoteSection':
            return <QuoteComponent key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_DisplayLeftTextRightImageSection':
            return <DisplayLeftTextRightImg key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_DisplayRightTextLeftImageSection':
            return <DisplayRightTextLeftImg key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_SectionDivider':
            return <SectionDivider key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_FaqSection':
            return <Faq key={section.id} {...section} />;

          case 'WpPage_Pagetemplate_Sections_ContactFormSection':
            return <ContactUsSection key={section.id} />;
          default:
            return null;
        }
      })}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      seo {
        title
        metaKeywords
        metaDesc
        focuskw
        canonical
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphImage {
          altText
          localFile {
            publicURL
          }
        }
        schema {
          raw
        }
      }
      pageTemplate {
        sections {
          __typename
          ...heroVideoSection
          ...heroImageSection
          ...productPageHeroSection
          ...textHeroSection
          ...bannerSection
          ...teamMemberSection
          ...textComponentSection
          ...productSection
          ...benefitsSection
          ...featuresDefinitionSection
          ...featuresSection
          ...longCenteredParagraphSection
          ...ctaSection
          ...singleImageSection
          ...quoteSection
          ...displayLeftTextRightImgSection
          ...displayRightTextLeftImgSection
          ...sectionDivider
          ...faqSection
          ...contactUsFormSection
        }
      }
    }
  }
`;

export default Page;
