/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import {
  featuresDefinitionSection,
  featuresDefinitionTitle,
  featuresDefinitionSubtitle,
  featuresDefinitionDescriptionAlign,
  featuresDescription,
  featureImageAlign,
} from '../styles/featuresDefinition.module.css';
import '../styles/featuresDefinitionImage.css';

export const fragment = graphql`
  fragment featuresDefinitionSection on WpPage_Pagetemplate_Sections_FeaturesDefinitionSection {
    title
    optionalSubTitle
    definitionDescription
    imageType
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            quality: 100
            webpOptions: { quality: 60 }
          )
        }
      }
    }
  }
`;

const FeaturesDefinition = ({
  title,
  optionalSubtitle,
  definitionDescription,
  imageType,
  image,
}) => {
  const featureImage = getImage(image?.localFile);

  return (
    <section className={featuresDefinitionSection}>
      <div className={featuresDefinitionTitle}>
        <h2>{title}</h2>
        <span className={featuresDefinitionSubtitle}>{optionalSubtitle}</span>
      </div>
      <div className={featuresDefinitionDescriptionAlign}>
        <div
          className={featuresDescription}
          dangerouslySetInnerHTML={{ __html: definitionDescription }}
        />
        <div className={featureImageAlign}>
          <GatsbyImage
            image={featureImage}
            alt={image?.altText}
            className={imageType}
          />
        </div>
      </div>
    </section>
  );
};

export default FeaturesDefinition;
