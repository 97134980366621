/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import {
  bannerAlign,
  bannerImageSection,
  bannerTitleRow,
} from '../styles/banner.module.css';

export const fragment = graphql`
  fragment bannerSection on WpPage_Pagetemplate_Sections_BannerSection {
    banner {
      bannerTitle
      bannerImage {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              webpOptions: { quality: 70 }
              quality: 100
            )
          }
        }
      }
    }
  }
`;

const Banner = ({ banner }) => {
  const image = getImage(banner.bannerImage.localFile);

  return (
    <div className={bannerAlign}>
      <GatsbyImage
        image={image}
        className={bannerImageSection}
        alt={banner.bannerImage.altText}
      />
      <div className={bannerTitleRow}>
        <h1>{banner.bannerTitle}</h1>
      </div>
    </div>
  );
};

export default Banner;
