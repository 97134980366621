/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { BiLoaderAlt } from '@react-icons/all-files/bi/BiLoaderAlt';
import { IoCloseOutline } from '@react-icons/all-files/io5/IoCloseOutline';
import { Box } from 'theme-ui';
import playIcon from '../../images/Play.svg';
import {
  heroSection,
  heroColumnLeft,
  btnPrimaryBannerAlign,
  btnPrimary,
  heroColumnRight,
  videoPlaceholder,
  btnVideoBanner,
  imagePlaceholderStyle,
  spinnerAlign,
  spinnerStyle,
  modalBg,
  modalAlign,
  modalContent,
  closeBtnModal,
  modalVideoAlign,
  modalVideoStyle,
} from '../styles/heroVideo.module.css';

export const fragment = graphql`
  fragment heroVideoSection on WpPage_Pagetemplate_Sections_Hero {
    button
    buttonUrl
    title
    introText
    description
    videoUrl
    videoTextCta {
      color
      text
    }
    imagePlaceholder {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            layout: FULL_WIDTH
            webpOptions: { quality: 70 }
            quality: 100
          )
        }
      }
    }
  }
`;

const HeroVideo = ({
  button,
  buttonUrl,
  title,
  introText,
  description,
  videoUrl,
  videoTextCta,
  imagePlaceholder,
}) => {
  const [modal, setModal] = useState(false);
  const [videoLoading, setVideoLoading] = useState(true);

  const handleOpenModal = () => {
    setModal((prev) => !prev);
  };

  const handleSpinner = () => {
    setVideoLoading((prev) => !prev);
  };

  const placeholder = getImage(imagePlaceholder?.localFile);

  return (
    <section className={heroSection}>
      <div className={heroColumnLeft}>
        <span>{introText}</span>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        <div className={btnPrimaryBannerAlign}>
          <Link className={btnPrimary} to={buttonUrl}>
            {button}
          </Link>
        </div>
      </div>
      <div className={heroColumnRight}>
        <GatsbyImage
          image={placeholder}
          className={videoPlaceholder}
          alt={imagePlaceholder.altText}
        />
        <div className={imagePlaceholderStyle}>
          <button
            onClick={handleOpenModal}
            className={btnVideoBanner}
            type="button"
          >
            {modal && (
              <section className={modalBg}>
                <div className={modalAlign}>
                  <div className={modalContent} modal={modal}>
                    <IoCloseOutline
                      className={closeBtnModal}
                      arial-label="Close modal"
                      onClick={setModal}
                    />
                    <div className={modalVideoAlign}>
                      {videoLoading && (
                        <div className={spinnerAlign}>
                          <BiLoaderAlt className={spinnerStyle} fadeIn="none" />
                        </div>
                      )}
                      <iframe
                        className={modalVideoStyle}
                        onLoad={handleSpinner}
                        loading="lazy"
                        width="800"
                        height="500"
                        src={videoUrl}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
            <img
              src={playIcon}
              alt="play icon"
              loading="eager"
              fadeIn="false"
            />
            <Box sx={{ color: videoTextCta.color }}>
              <small>{videoTextCta.text}</small>
            </Box>
          </button>
        </div>
      </div>
    </section>
  );
};

export default HeroVideo;
