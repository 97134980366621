/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import { GoQuote } from '@react-icons/all-files/go/GoQuote';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  quoteComponentSection,
  quoteComponentStyle,
  blockquoteAlign,
  blockquoteStyle,
  quoteAuthorStyle,
  quoteLogo,
  quoteIcon,
} from '../styles/quoteComponent.module.css';

export const fragment = graphql`
  fragment quoteSection on WpPage_Pagetemplate_Sections_QuoteSection {
    quoteAuthor
    quoteText
    quoteBrand {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            quality: 100
            webpOptions: { quality: 70 }
          )
        }
      }
    }
  }
`;

const QuoteComponent = ({ quoteBrand, quoteText, quoteAuthor }) => {
  const quoteBrandImg = getImage(quoteBrand?.localFile);

  return (
    <section className={quoteComponentSection}>
      <div className={quoteComponentStyle}>
        <div className={blockquoteAlign}>
          <div>
            <GatsbyImage
              image={quoteBrandImg}
              className={quoteLogo}
              alt={quoteBrand?.altText}
            />
          </div>
          <GoQuote className={quoteIcon} />
          <div
            dangerouslySetInnerHTML={{
              __html: quoteText,
            }}
            className={blockquoteStyle}
          />
          <div
            className={quoteAuthorStyle}
            dangerouslySetInnerHTML={{
              __html: quoteAuthor,
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default QuoteComponent;
