/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import { FaCircle } from '@react-icons/all-files/fa/FaCircle';
import { Box, Link } from 'theme-ui';
import {
  productDescriptionBackground,
  productDescriptionAlign,
  productDescriptionLeft,
  productDescriptionTitle,
  productDescriptionLeftText,
  productDescriptionBulletpoint,
  productDescriptionRight,
  productDescriptionRightInternalLink,
  productDescriptionBtn,
  btnSecondary,
} from '../styles/product.module.css';

export const fragment = graphql`
  fragment productSection on WpPage_Pagetemplate_Sections_ProductDescription {
    title
    backgroundColor
    typographyColor
    firstColumn
    secondColumn
    anchorText {
      anchorTextColor
      text
      textUrl
    }
    buttonProduct {
      buttonColor
      borderButtonColor
      buttonTextColor
      buttonText
      buttonUrl
      buttonHoverColor
      buttonTextHover
      buttonBorderHover
    }
  }
`;

const Product = ({
  title,
  firstColumn,
  secondColumn,
  anchorText,
  buttonProduct,
  backgroundColor,
  typographyColor,
}) => {
  return (
    <Box
      sx={{ background: backgroundColor }}
      className={productDescriptionBackground}
    >
      <Box sx={{ color: typographyColor }} className={productDescriptionAlign}>
        <div className={productDescriptionLeft}>
          <div className={productDescriptionTitle}>
            <FaCircle className={productDescriptionBulletpoint} />
            <h3>{title}</h3>
          </div>
          <div
            className={productDescriptionLeftText}
            dangerouslySetInnerHTML={{ __html: firstColumn }}
          />
        </div>
        <div>
          <div className={productDescriptionRight}>
            <div dangerouslySetInnerHTML={{ __html: secondColumn }} />
          </div>
          <div className={productDescriptionRightInternalLink}>
            <Link
              sx={{ color: anchorText.anchorTextColor }}
              href={anchorText.textUrl}
            >
              {anchorText.text}
            </Link>
          </div>
          <div className={productDescriptionBtn}>
            <Link
              sx={{
                background: buttonProduct.buttonColor,
                borderColor: buttonProduct.borderButtonColor,
                color: buttonProduct.buttonTextColor,
                '&:hover': {
                  borderColor: buttonProduct.buttonBorderHover,
                  background: buttonProduct.buttonHoverColor,
                  color: buttonProduct.buttonTextHover,
                },
              }}
              className={btnSecondary}
              href={buttonProduct.buttonUrl}
            >
              {buttonProduct.buttonText}
            </Link>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Product;
