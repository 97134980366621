// extracted by mini-css-extract-plugin
export var btnSecondary = "product-module--btnSecondary--ISqB6";
export var productDescriptionAlign = "product-module--productDescriptionAlign--jpil4";
export var productDescriptionBackground = "product-module--productDescriptionBackground--fube7";
export var productDescriptionBtn = "product-module--productDescriptionBtn--tgQym";
export var productDescriptionBulletpoint = "product-module--productDescriptionBulletpoint--bD4C7";
export var productDescriptionLeft = "product-module--productDescriptionLeft--GiYEH";
export var productDescriptionLeftText = "product-module--productDescriptionLeftText--uTQXz";
export var productDescriptionRight = "product-module--productDescriptionRight--xXdhE";
export var productDescriptionRightInternalLink = "product-module--productDescriptionRightInternalLink--YuZjB";
export var productDescriptionTitle = "product-module--productDescriptionTitle--JHYjz";