/* eslint-disable react/prop-types */

import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  heroImageSection,
  heroImageColumnLeft,
  btnPrimary,
  btnPrimaryBannerAlign,
  heroImageColumnRight,
  imagePlaceholder,
} from '../styles/heroImage.module.css';

export const fragment = graphql`
  fragment heroImageSection on WpPage_Pagetemplate_Sections_HeroImage {
    introText
    title
    description
    button
    buttonUrl
    image {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(
            placeholder: TRACED_SVG
            quality: 100
            webpOptions: { quality: 70 }
            layout: FULL_WIDTH
          )
        }
      }
    }
  }
`;

const HeroImage = ({
  introText,
  title,
  description,
  button,
  buttonUrl,
  image,
}) => {
  const heroImage = getImage(image.localFile);

  return (
    <section className={heroImageSection}>
      <div className={heroImageColumnLeft}>
        <span>{introText}</span>
        <h1>{title}</h1>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        <div className={btnPrimaryBannerAlign}>
          <Link className={btnPrimary} to={buttonUrl}>
            {button}
          </Link>
        </div>
      </div>
      <div className={heroImageColumnRight}>
        <div>
          <GatsbyImage
            image={heroImage}
            className={imagePlaceholder}
            alt={image.altText}
            loading="eager"
          />
        </div>
      </div>
    </section>
  );
};

export default HeroImage;
