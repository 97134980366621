/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { singleImageAlign } from '../styles/singleImage.module.css';
import '../styles/singleImageSize.css';

export const fragment = graphql`
  fragment singleImageSection on WpPage_Pagetemplate_Sections_ImageSection {
    fieldGroupName
    imageSize
    imageSection {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: TRACED_SVG
            quality: 100
          )
        }
      }
    }
  }
`;

const SingleImage = ({ imageSection, imageSize }) => {
  const singleImage = getImage(imageSection.localFile);
  return (
    <section className={singleImageAlign}>
      <GatsbyImage
        className={imageSize}
        image={singleImage}
        alt={imageSection.altText}
      />
    </section>
  );
};

export default SingleImage;
