/* eslint-disable react/prop-types */

import React, { useState, useRef, useEffect } from 'react';
import { FiPlus } from '@react-icons/all-files/fi/FiPlus';
import {
  questionStyling,
  faqAccordionStyling,
  questionAlign,
  bridgeIconStyling,
  accordionAnswer,
  accordionAnswerDivider,
  faqSingleIcon,
  rotate,
} from '../styles/FaqSingle.module.css';
import bridgeIcon from '../../images/soloLogo.svg';

const FaqSingle = ({ question, answer }) => {
  const [active, setActive] = useState(false);

  const contentRef = useRef(null);

  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : '0px';
  }, [contentRef, active]);

  const toggleAccordion = () => {
    setActive(!active);
  };
  return (
    <div>
      <button
        type="button"
        className={`${faqAccordionStyling} ${active}`}
        onClick={toggleAccordion}
      >
        <div>
          <div className={questionAlign}>
            <img
              srcSet={bridgeIcon}
              className={bridgeIconStyling}
              alt="faq question icon"
              loading="lazy"
              fadeIn="false"
            />
            <h4 className={questionStyling}>{question}</h4>
            <FiPlus
              className={
                active ? `${faqSingleIcon} ${rotate}` : `${faqSingleIcon}`
              }
            />
          </div>
          <div
            ref={contentRef}
            className={
              active
                ? `${accordionAnswer} ${accordionAnswerDivider} `
                : `${accordionAnswer}`
            }
            dangerouslySetInnerHTML={{ __html: answer }}
          />
        </div>
      </button>
    </div>
  );
};

export default FaqSingle;
