/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import {
  featuresSectionAlign,
  featuresSectionTitle,
  featureCol,
} from '../styles/features.module.css';
import FeaturesCol from './FeaturesCol';

export const fragment = graphql`
  fragment featuresSection on WpPage_Pagetemplate_Sections_FeaturesSection {
    title
    features {
      title
      description
      iconDisplay
      icons {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 100
              placeholder: TRACED_SVG
              webpOptions: { quality: 70 }
            )
          }
        }
      }
    }
  }
`;

const Features = ({ features, title }) => {
  return (
    <section>
      <div className={featuresSectionTitle}>
        <h3>{title}</h3>
      </div>
      <div className={featuresSectionAlign}>
        <div className={featureCol}>
          {features.map((feature, index) => {
            return <FeaturesCol key={feature.id} index={index} {...feature} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Features;
