/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import { faqSection, faqTitle } from '../styles/Faq.module.css';
import FaqSingle from './FaqSingle';

export const fragment = graphql`
  fragment faqSection on WpPage_Pagetemplate_Sections_FaqSection {
    title
    frequentlyAskedQuestions {
      question
      answer
    }
  }
`;

const Faq = ({ title, frequentlyAskedQuestions }) => {
  return (
    <section className={faqSection}>
      <div className={faqTitle} dangerouslySetInnerHTML={{ __html: title }} />
      <div>
        {frequentlyAskedQuestions.map((frequentlyAskedQuestion, index) => {
          return (
            <FaqSingle
              key={frequentlyAskedQuestion.id}
              index={index}
              {...frequentlyAskedQuestion}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Faq;
