/* eslint-disable react/prop-types */

import React from 'react';
import { Box } from 'theme-ui';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  imagePlaceholder,
  benefitStatementImg,
  benefitsStatementsItemsCol,
  benefitsStatementColText,
} from '../styles/benefitsCol.module.css';

const BenefitsCol = ({ title, description, image, color }) => {
  const benefitsImages = getImage(image?.localFile);

  return (
    <div className={benefitsStatementsItemsCol}>
      <div className={imagePlaceholder}>
        <GatsbyImage
          image={benefitsImages}
          className={benefitStatementImg}
          alt={image?.altText}
        />
      </div>
      <Box className={benefitsStatementColText}>
        <h4 style={{ color }}>{title}</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        />
      </Box>
    </div>
  );
};

export default BenefitsCol;
