/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import { heroTitleSection, heroTitle } from '../styles/textHero.module.css';

export const fragment = graphql`
  fragment textHeroSection on WpPage_Pagetemplate_Sections_TextHeroSection {
    titleColor
    title
    descriptionDisplay
    description
  }
`;

const TextHero = ({ titleColor, title, descriptionDisplay, description }) => {
  return (
    <section className={heroTitleSection}>
      <div className={heroTitle}>
        <h1 className={titleColor}>{title}</h1>
        {descriptionDisplay ? (
          <div dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
      </div>
    </section>
  );
};

export default TextHero;
