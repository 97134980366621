/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import { Box, Heading } from 'theme-ui';
import {
  benefitsSectionTitle,
  benefitsStatementsItemsRow,
} from '../styles/benefits.module.css';
import BenefitsCol from './BenefitsCol';

export const fragment = graphql`
  fragment benefitsSection on WpPage_Pagetemplate_Sections_BenefitStatementsSection {
    background
    title
    typographyColorColumns
    typographyColorTitle
    columns {
      title
      description
      image {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              quality: 100
              webpOptions: { quality: 70 }
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
  }
`;

const Benefits = ({
  typographyColorTitle,
  typographyColorColumns,
  background,
  title,
  columns,
}) => {
  return (
    <Box sx={{ background }}>
      <div className={benefitsSectionTitle}>
        <Heading as="h3" sx={{ color: typographyColorTitle }}>
          {title}
        </Heading>
      </div>
      <Box
        className={benefitsStatementsItemsRow}
        sx={{ color: typographyColorColumns }}
      >
        {columns.map((column, index) => {
          return (
            <BenefitsCol
              key={column.id}
              index={index}
              color={typographyColorColumns}
              {...column}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Benefits;
