/* eslint-disable react/prop-types */

import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  teamMemberAlign,
  teamMemberImgAlign,
  teamMemberImg,
  teamMemberInfo,
  teamMemberPosition,
  teamMemberName,
  memberDescriptionAlign,
} from '../styles/teamMemberSingle.module.css';

const TeamMemberSingle = ({
  positionRole,
  memberName,
  memberDescription,
  teamMemberImage,
}) => {
  const memberImg = getImage(teamMemberImage.localFile);

  return (
    <div className={teamMemberAlign}>
      <div className={teamMemberImgAlign}>
        <GatsbyImage
          image={memberImg}
          alt={teamMemberImage.altText}
          className={teamMemberImg}
        />
      </div>
      <div className={teamMemberInfo}>
        <div className={teamMemberPosition}>
          <span>{positionRole}</span>
        </div>
        <div className={teamMemberName}>
          <h3>{memberName}</h3>
        </div>
        <div
          className={memberDescriptionAlign}
          dangerouslySetInnerHTML={{ __html: memberDescription }}
        />
      </div>
    </div>
  );
};

export default TeamMemberSingle;
