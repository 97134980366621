/* eslint-disable react/prop-types */

import React from 'react';
import { graphql } from 'gatsby';
import {
  textComponentArticle,
  textComponentSection,
  textComponentTitle,
  textComponentDescription,
} from '../styles/textComponent.module.css';
import '../styles/textComponentStyle.css';

export const fragment = graphql`
  fragment textComponentSection on WpPage_Pagetemplate_Sections_TextComponentSection {
    title
    descriptionWidth
    description
  }
`;

const TextComponent = ({ title, description, descriptionWidth }) => {
  return (
    <section className={textComponentSection}>
      <article className={textComponentArticle}>
        <div className={textComponentTitle}>
          <div dangerouslySetInnerHTML={{ __html: title }} />
        </div>
        <div className={textComponentDescription}>
          <div
            className={descriptionWidth}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </article>
    </section>
  );
};

export default TextComponent;
